<template>
  <div class="reviewsubmitTest">
    <div class="leftnav">
      <step-secont :active="2" />
    </div>
    <main>
      <div class="boxLeft">
        <!-- 选择 -->
        <div class="select">
          <div class="title">
            <div class="tgIndex">{{ currentIndex + 1 + '、' }}</div>
            <div class="tg" v-html="currentExercises.tg" style="font-family: 'Times New Roman', Times, serif;" > </div>
          </div>
          <div class="item" v-for="item,index in currentExercises.optionsList" :key="index" @click="select(item, index)" :class="{ yes: yesAnswerIndex === index, no: noAnswerIndex === index }">
            <div class="xxmc">{{ item.xxmc + '、' }}</div>
            <div class="xxnr" v-html="item.xxnr"></div>
          </div>
        </div>
      </div>
      <div class="right">
        <div class="title">
          <p> {{ details.kwmc.substring(0,9) }} </p>
          <p> {{ details.kwmc.substring(9) }} </p>
        </div>
        <div class="box" id="box">
          <p v-if="yesAnswerIndex !== null">解析：</p>
          <div v-if="yesAnswerIndex !== null" v-html="currentExercises.jx"></div>
        </div>
        <div class="right-footer" id="footer">
          <div class="text">
            <p>&nbsp;</p>
            <p>拓展练习2</p>
          </div>
          <div class="bottom">
            <div @click="next" v-if="currentIndex + 1 !== exercisesList.length">下一题</div>
            <div @click="toLink" v-else>完  成</div>
          </div>
        </div>
      </div>
    </main>
    <Header :header_data="header_data" :noClicking="true" />
  </div>
</template>
<script>
import Header from "../components/zjfheader.vue";
import { onMounted, reactive, toRefs } from "vue-demi";
import { getCurrentInstance } from 'vue'
import { ElMessage } from "element-plus"
import { getEnglishText, getExercises } from '@/api/student'
import { useStore } from 'vuex'
import StepSecont from '../components/stepSecont.vue';
export default {
  components: {
    Header,
    StepSecont,
  },
  setup() {
    const data = reactive({
      details: { kwmc: '' },
      header_data: [],
      yesAnswerIndex: null, // 正确答案
      noAnswerIndex: null, // 错误答案
      exercisesList: [], // 全部题目
      currentExercises: {}, // 当前题目
      currentIndex: 0,
      bingo: 0
    })
    const store = useStore()
    const { appContext } = getCurrentInstance()
    const that = appContext.config.globalProperties
    onMounted(() => {
      // 获取课文
      getEnglishText({ kwid: that.$route.query.kwid }).then(res => {
        if(res.success) {
          data.details = res.result
          data.header_data = [
            { title: "图谱英语 — ", path: "newEnglish" },
            { title: that.$route.query.zmc + " — ", path: `/units?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}` },
            { title: that.$route.query.dymc + " — ", path: `/chapter?zid=${that.$route.query.zid}&zmc=${that.$route.query.zmc}&dyid=${that.$route.query.dyid}&dymc=${that.$route.query.dymc}` },
            { title: res.result.kwmc, path: "" },
          ]
        } else {
          ElMessage({
            message: res.resultDesc,
            type: 'warning'
          })
        }
      })
      // 获取全部单选题
      getExercises({ kwid: that.$route.query.kwid, dyid: that.$route.query.dyid, tmlx: 1, zid: that.$route.query.zid, zsdid: '' }).then(res => {
        if(res.success) {
          data.exercisesList = res.result
          data.currentExercises = data.exercisesList[data.currentIndex]
        } else {
          ElMessage({ message: res.resultDesc, type: 'warning' })
        }
      })
    })
    // 答题
    const select = (item, index) => {
      if (data.yesAnswerIndex !== null) {
        return false
      }
      if (item.sfzqda) {
        data.yesAnswerIndex = index
        data.bingo++
      } else {
        data.noAnswerIndex = index
        data.currentExercises.optionsList.forEach((ele,i) => {
          if (ele.sfzqda) {
            data.yesAnswerIndex = i
          }
        })
      }
    }
    // 下一题
    const next = () => {
      if (data.yesAnswerIndex === null) {
        ElMessage({ message: '先答此题才能进行下一题哦~', type: 'warning' })
        return false
      }
      data.yesAnswerIndex = null
      data.noAnswerIndex = null
      data.currentIndex++
      data.currentExercises = data.exercisesList[data.currentIndex]
    }
    // 进入测验
    const toLink = () => {
      if (data.yesAnswerIndex === null) {
        ElMessage({ message: '先答题还未结束哦~', type: 'warning' })
        return false
      }
      that.$router.replace({ name: 'reviewresult', query: { fs: parseInt(data.bingo / data.exercisesList.length * 100), dyid: that.$route.query.dyid, dymc: that.$route.query.dymc, zmc: that.$route.query.zmc, zid: that.$route.query.zid, kwid: that.$route.query.kwid} })
    }
    return {
      ...toRefs(data),
      select,
      next,
      toLink,
    };
  },
};
</script>
<style lang="scss" scoped>
@media (max-height: 700px) {
  #score {
    margin: 0;
  }
}
.reviewsubmitTest {
  width: 100%;
  height: 100%;
  background: url("../../../assets/student/zjfstudyBanner.png") no-repeat;
  background-size: 100% 100%;
  .leftnav {
    height: 65%;
    position: absolute;
    left: 4.5vw;
    top: 9vw;
    z-index: 3;
  }
  main {
    width: 94.78%;
    height: 83.925%;
    background: url("../../../assets/student/wordbanner.png") no-repeat;
    background-size: 100% 100%;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    padding: .2vw .2vw .2vw 0;
    .boxLeft {
      width: 71.2vw;
      height: 100%;
      float: left;
      box-sizing: border-box;
      padding: 1vw 0 1vw 2vw;
      position: relative;
      .select {
        position: absolute;
        top: 5.4895vw;
        left: 12%;
        right: 5%;
        bottom: 5.4895vw;
        position: absolute;
        .title {
          font-size: 1.875vw;
          color: #333;
          margin-bottom: 2vw;
          overflow: hidden;
          width: 100%;
          .tgIndex{
            float: left;
            width: 60px;
          }
          .tg{
            float: left;
            width: calc(100% - 60px);
            /deep/p{
              font-family: 'Times New Roman', Times, serif;
            }
          }
        }
        .item {
          font-size: 1.8vw;
          font-weight: 400;
          padding: 20px 0;
          box-sizing: border-box;
          overflow: hidden;
          cursor: pointer;
          .xxmc{
            float: left;
            width: 60px;
            font-family: 'Times New Roman', Times, serif;
          }
          .xxnr{
            float: left;
            position: relative;
            max-width: calc(100% - 150px);
            /deep/p {
              font-family: 'Times New Roman', Times, serif;
            }
          }
        }
        .item.yes{
          color: #2CA2F5;
        }
        .item.yes .xxnr::after{
          content: '';
          width: 84px;
          height: 66px;
          position: absolute;
          top: 0;
          right: -90px;
          background: url("../../../assets/student/yesAnswer.png") no-repeat;
        }
        .item.no{
          color: #E60000;
        }
        .item.no .xxnr::after{
          content: '';
          width: 84px;
          height: 66px;
          position: absolute;
          top: 0;
          right: -90px;
          background: url("../../../assets/student/noAnswer.png") no-repeat;
        }
      }
    }
    .right {
      height: 100%;
      width: 23.3vw;
      // border: 6px solid #2CA2F5;
      border-radius: 3.125vw;
      box-sizing: border-box;
      padding-top: 1vw;
      position: relative;
      .title {
        width: 100%;
        text-align: center;
        font-size: 1.6vw;
        font-weight: 600;
        p {
          font-family: 'Times New Roman', Times, serif;
        }
      }
      .box {
        position: absolute;
        top: 16%;
        bottom: 30%;
        right: -.18vw;
        width: 100%;
        font-size: 1.3vw;
        box-sizing: border-box;
        padding: 0 2vw;
        overflow: auto;
        p{
          padding-bottom: 10px;
          font-size: 1.5vw;
        }
      }
      .right-footer {
        width: 100%;
        height: 30%;
        position: absolute;
        bottom: 0;
        // border-top: 5px solid #2CA2F5;
        .text {
          padding-top: 5%;
          text-align: center;
          font-weight: bold;
          p {
            font-size: 1.2vw;
          }
        }
        .bottom {
          position: absolute;
          bottom: 10%;
          width: 100%;
          display: flex;
          justify-content: space-around;
          div:nth-child(1) {
            width: 8vw;
            height: 3.33333vw;
            background: url('../../../assets/student/wordysxbtn.png') no-repeat;
            background-size: 100% 100%;
            color: #fff;
            font-size: 1.258333vw;
            text-align: center;
            line-height: 3.333vw;
            cursor: pointer;
            border-radius: 60px;
          }
        }
      }
    }
  }
  .active {
    color: #578EFF !important;
    font-size: 1.5vw !important;
  }
  .activeNext {
    color: #000000 !important;
    font-size: 1.4vw !important;
  }
  ::-webkit-scrollbar {
    width: .6vw;
  }
  ::-webkit-scrollbar-thumb {
    background: #fff;
    border-radius: 20px;
  }
  // 修改popover样式
  .el-popper.is-light {
    top: -10px !important;
  }
}
</style>